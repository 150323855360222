import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ImageHero from "../images/hero-clipboard-with-checklist.png"
import ImageLineLong from "../images/line-long.png"
import ImageCirclesLines from "../images/therapeutic-circles-lines.png"
import ImageTarget from "../images/therapeutic-target.png"
import ImageHead from "../images/impact-patient-neck-pain-and-stiffness.png"
import ImageQuoteAlwaysLooming from "../images/quote-its-always-looming.png"
import ImageBgPhysicianSuggested from "../images/impact-bg-physician-suggested.png"
import ImageLinesCirlesItsAlmost from "../images/impact-lines-cicles-its-always.png"
import ImageIconCalloutArrow from "../images/icon-callout-brown-arrow.svg"

const ImpactOnPatients = () => (
  <Layout pageid="impact-on-patients">
    <Seo title="Impact on Patients | PMR and IL-6 HCP Website" description="Read about the impact of PMR on patients." ogDesc="Read about the impact of PMR on patients." lang="en-US"/>
    <div className="content-container">
      <div id="hero-image-container" className="mb-9 md_mb-9">
        <h1>IMPACT ON PATIENTS</h1>
        <img src={ImageHero} alt="clipboard with checklist" id="hero-impact-on-patients" />
      </div>

      <div id="impact-physician-suggested">
        <img src={ImageTarget} alt="" id="image-impact-target" />
        <div className="content-container">
          <h2 className="section-headline" style={{ width: '80%' }}>The physical symptoms of PMR may have serious impact on patients</h2>
          <p><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
          <ul>
            <li>PMR has been perceived as a mild condition, however patients report <strong className="color-orange-rust">high levels of pain and stiffness</strong> that tend to persist throughout the day<sup>1</sup></li>
            <li>People suffering from PMR have a <strong className="color-orange-rust">lower quality of life</strong> compared with population norms<sup>2,3</sup></li>
            <li>People living with PMR may have <strong className="color-orange-rust">trouble getting out of bed and performing daily activities</strong> such as brushing their hair, driving, or taking a shower<sup>2</sup></li>
          </ul>
        </div>
        <div className="callout-orange align-right mb-3 md_mb-3">
          <p>There is an unmet need for patients and further exploration is needed to expose the underlying mechanisms of PMR</p>
        </div>
      </div>
      <img src={ImageBgPhysicianSuggested} alt="" id="image-bg-physician-suggested" className="show-for-large" />

      <img src={ImageLinesCirlesItsAlmost} alt="" className="hide-for-large mt-2 mb-2 md_mt-3 md_mb-3" style={{ display: 'block', margin: '0 0 0 auto' }} />

      <div className="row" style={{ maxWidth: 'none' }}>
        <div className="colums small-12 medium-12 large-6 mb-6 md_mb-6 lg_mb-0">
          <div className="emotional-impact-boxes left">
            <img src={ImageQuoteAlwaysLooming} alt="" />
          </div>
        </div>
        <div className="colums small-12 medium-12 large-6">
          <div className="emotional-impact-boxes right">
            <h2 className="section-headline">The emotional impact of PMR can take its toll</h2>
            <p><img src={ImageLineLong} alt="" className="mb-2 mt-2 md_mb-2 md_mt-2"/></p>
            <ul>
              <li>Studies have shown a prevalence of depressive symptoms in approximately one third of people living with PMR<sup>4</sup></li>
            </ul>
          </div>
        </div>
      </div>

      <div><img src={ImageCirclesLines} alt="" /></div>
      <img src={ImageHead} alt="neck pain and stiffness" id="image-head" />

      <Link to="/resources/" className="gen-brown-callout">
        <div className="callout-brown-generic right mb-2 md_mb-2" style={{ width: '75%', position: 'relative', marginTop:'-450px' }}>
          <div className="row">
            <div className="columns small-12 medium-8 text-center">Explore useful resources <span className="nowrap-desktop">about PMR</span></div>
            <div className="columns small-12 medium-4 text-center nowrap align-self-middle"><img src={ImageIconCalloutArrow} alt="" /></div>
          </div>
        </div>
      </Link>

      <div id="references" className="impact">
        <div id="references-content">
          <p>
            <strong>References: 1.</strong> Muller S, Hider SL, Helliwell T, et al. Characterising those with incident polymyalgia rheumatica in primary care: results from the PMR cohort study. <em>Arthritis Research & Therapy.</em> 2016;18(1):200.&nbsp;
            <strong>2.</strong> Acharya S, Musa R. Polymyalgia Rheumatica. <em>NCBI Bookshelf.</em> StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2022.&nbsp;
            <strong>3.</strong> Hutchings A, Hollywood J, Lamping D, et al. Clinical outcomes, quality of life, and diagnostic uncertainty in the first year of polymyalgia rheumatica. <em>Arthritis & Rheumatism.</em> 2007;57(5):803-809.&nbsp;
            <strong>4.</strong> Vivekanantham A, Blagojevic-Bucknall M, Clarkson K, et al. How common is depression in patients with polymyalgia rheumatica? <em>Clin Rheumatol.</em> 2018;37(6):1633-1638.&nbsp;
          </p>
        </div>
      </div>

    </div>
  </Layout>
)

export default ImpactOnPatients
